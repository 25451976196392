import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-tulsa-oklahoma.png'
import image0 from "../../images/cities/scale-model-of-cry-baby-hill-in-tulsa-oklahoma.png"
import image1 from "../../images/cities/scale-model-of-rose-district-fountain-in-tulsa-oklahoma.png"
import image2 from "../../images/cities/scale-model-of-tulsa-air-and-space-museum-&-planetarium-in-tulsa-oklahoma.png"
import image3 from "../../images/cities/scale-model-of-woody-guthrie-center-in-tulsa-oklahoma.png"
import image4 from "../../images/cities/scale-model-of-broken-arrow-historical-soc-in-tulsa-oklahoma.png"
import image5 from "../../images/cities/scale-model-of-tulsa-zoo-in-tulsa-oklahoma.png"
import image6 from "../../images/cities/scale-model-of-guthrie-green-in-tulsa-oklahoma.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Tulsa'
            state='Oklahoma'
            image={image}
            lat='36.1539816'
            lon='-95.992775'
            attractions={ [{"name": "Cry Baby Hill", "vicinity": "735 W 13th St, Tulsa", "types": ["point_of_interest", "establishment"], "lat": 36.1442178, "lng": -96.00075199999998}, {"name": "Rose District Fountain", "vicinity": "416 S Main St, Broken Arrow", "types": ["point_of_interest", "establishment"], "lat": 36.0488243, "lng": -95.7910134}, {"name": "Tulsa Air and Space Museum & Planetarium", "vicinity": "3624 N 74th E Ave, Tulsa", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.2067942, "lng": -95.89566639999998}, {"name": "Woody Guthrie Center", "vicinity": "102 E Mathew B. Brady St, Tulsa", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.1586461, "lng": -95.99224129999999}, {"name": "Broken Arrow Historical Soc", "vicinity": "400 S Main St, Broken Arrow", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 36.0492032, "lng": -95.7911229}, {"name": "Tulsa Zoo", "vicinity": "6421 E 36th St N, Tulsa", "types": ["zoo", "point_of_interest", "establishment"], "lat": 36.210948, "lng": -95.9078854}, {"name": "Guthrie Green", "vicinity": "111 East M.B. Brady Street, Tulsa", "types": ["park", "point_of_interest", "establishment"], "lat": 36.1592953, "lng": -95.9919228}] }
            attractionImages={ {"Cry Baby Hill":image0,"Rose District Fountain":image1,"Tulsa Air and Space Museum & Planetarium":image2,"Woody Guthrie Center":image3,"Broken Arrow Historical Soc":image4,"Tulsa Zoo":image5,"Guthrie Green":image6,} }
       />);
  }
}